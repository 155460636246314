import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const philosophyPage = () => {
    return (
      <Layout>
         <Seo
        title="Contact"
        description="Let us know."/>
        <div className="flex flex-col lg:flex-row justify-center items-center h-screen">
          <div className="lg:w-1/2 flex flex-col items-end">
            <h1 className="text-right text-4xl lg:text-6xl font-semibold mx-5">Say hi.</h1>
            <p className=" lg:w-1/2 text-right text-xl mx-5 py-10">Whether if you want to send custom product inquiries, apply for a job, give feedback or just chitchat, use this form to get in contact with us.</p>
          </div>
          <div className="lg:w-1/2">
            <form className="flex flex-col lg:w-1/2 m-auto" id="contactform" action="https://formsubmit.co/hallo@heinrig.tech" method="POST">
            <label htmlFor="name" className="text-sm">your name</label>
            <input name="name" type="text" id="name" className="mb-5"></input>
            <label htmlFor="email" className="text-sm">your email address</label>
            <input name="email" type="email" id="email" className="mb-5"></input>
            <label htmlFor="message" className="text-sm">your message</label>
            <textarea name="message" id="message" rows="2" className="mb-5" placeholder="What do you want us to know?"></textarea>
            <input name="_honey" type="text" className="hidden"></input>
            <input value="Submit" type="submit" className="button max-w-max m-auto"/>
            </form>
          </div>
        </div>
      </Layout>
  )
}

export default philosophyPage